/**
 * Reusable tags subheading.
 * Part of the `PostSubHeader` component.
 *
 */

import * as React from "react"
import PropTypes from "prop-types"

import kebabCase from "lodash/kebabCase"

import { cleanArrayTags } from "../utils"

const PostTags = ({ tags }) => {
  const sanitizedTags = cleanArrayTags(tags)
  return (
    <span>
    {sanitizedTags.map((tag) =>
      <span
          key={tag}
          style={{
            marginRight: '2.5%',
            cursor: 'pointer',
          }}
      >
      <a
        href={`/tag/${kebabCase(tag)}/`}
        className={"subHeading"}
      >
        {tag}
      </a>
      </span>
    )}
    </span>
  )
}

PostTags.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.string
  )
}

export default PostTags
