/**
 * Reusable date parsing. This takes the dates
 * from "YYYYMMDDHHMMSS" -> "MM/DD/YYYY HH:MM".
 * Part of the `PostSubHeader` component.
 *
 */

import PropTypes from "prop-types"

const PostDate = ({ date }) => {

  const dateStr = date
  const year = dateStr.substring(0, 4)
  const month = dateStr.substring(4, 6)
  const day = dateStr.substring(6, 8)
  const hour = dateStr.substring(8, 10)
  const minute = dateStr.substring(10, 12)
  const fullDate = `${month}/${day}/${year} ${hour}:${minute}`
  return (
    fullDate
  )
}

PostDate.propTypes = {
  date: PropTypes.string
}

export default PostDate
