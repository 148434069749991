/**
 * Reusable subheading.
 * Part of `posts.js` and `blogPost.js`
 * pages.
 *
 */

import * as React from "react"
import PropTypes from "prop-types"

import PostDate from "./postDate"
import PostTags from "./postTags"
import "../styles/general.css"

const PostSubHeader = ({ category, date, tags }) => {
  return (
    <div>
      <h4
        className={"subHeading"}
        style={{
          marginBottom: '0',
        }}
      >
        <span><PostDate date={date}/> | Category: </span>
        <a
          key={category}
          href={`/category/${category}`}
          className={"subHeading"}
        >
          {category}
        </a>
      </h4>
      <h5
        className={"subHeading"}
        style={{
          marginBottom: '0'
        }}
      >
        <span>Tags: </span>
        <PostTags tags={tags}/>
      </h5>
    </div>
  )
}

PostSubHeader.propTypes = {
  category: PropTypes.string,
  date: PropTypes.string,
  tags: PropTypes.arrayOf(
    PropTypes.string
  )
}

export default PostSubHeader
