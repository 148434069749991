import * as React from "react"

import PostSubHeader from "./postSubHeader"
import { Link } from "gatsby"

const PostsList = ({ posts }) => {


  return (
    <div className="blog-posts">
      {posts
        .filter(post => post.node.frontmatter.title.length > 0)
        .map(({ node: post }) => {
          const fixedDescription = post.excerpt.replace(post.frontmatter.title + " ", "")
          return (
            <div
              key={post.id}
            >
              <h3>
                <Link
                  to={post.frontmatter.path}
                >
                {post.frontmatter.title}
                </Link>
              </h3>
              <PostSubHeader
                category={post.frontmatter.category}
                date={post.frontmatter.date}
                tags={post.frontmatter.tags}
              />
              <p>{fixedDescription}</p>
            </div>
          )
        })}
    </div>
  )
}

export default PostsList
